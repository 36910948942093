module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://om-nk-api-prod.herokuapp.com","apiKey":"e7220628-7f56-44e2-86cb-150cea4bd350","guideId":"545","siteUrl":"https://www.ronnearingsjon.se","lang":"sv","typesCategories":{"hiking":["33","109","116","7"],"paddling":["37"],"biking":["31","16","119"],"fishing":["26","118","105","115"],"food":["54","106"],"discover":["6","14","69","84","55","115","73","54","56","18","103","64","26","22","62","76","75","17","19","78","35","25","179","29","77","15","60","87","12","81","113","89","27","74","52","61"]},"includeListsInTours":true,"imagePlaceholderColor":"#588157","templates":{"type":"/repo/src/templates/type.js","site":"/repo/src/templates/site.js","page":"/repo/src/templates/page.js","articles":"/repo/src/templates/articles.js","articlesPost":"/repo/src/templates/articles-post.js","news":"/repo/src/templates/news.js","newsPost":"/repo/src/templates/news-post.js","events":"/repo/src/templates/events.js","eventsPost":"/repo/src/templates/events-post.js","tours":"/repo/src/templates/tours.js","toursPost":"/repo/src/templates/tours-post.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Rönne å och Ringsjön","short_name":"Rönne å och Ringsjön","description":"Vattennära upplevelser som du bär med dig länge!","theme_color":"#224d91","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
